import React, { FC } from "react";
import "./Login.css";
import * as AuthProxy from "./security/AuthProxy";
import { BrowserRouter as Router } from "react-router-dom";
import { Button, Footer } from "@better-ui/react";
//import { Button} from "@mui/material";
import "@better-ui/styles/optum/optum.css";
import Dialog from "./security/Dialog";


interface LoginProps {}

export interface LoginState {
  currentDateTime: String;
  showAlertDialog: boolean;
}


export default class Login extends React.Component<LoginProps, LoginState> {



  private intervalID: any;

  constructor(props: any){
    super(props);
    this.state = { 
      currentDateTime: Date().toLocaleString(),
      showAlertDialog: false,      
    };
  }

  componentDidMount() {
    window.process = { ...window.process } 
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    this.setState({
      currentDateTime: new Date().toLocaleString('en-US', { hour12: false })
    });
  }

   // Handler to open the dialog  
   openDialog = () => {  
    this.setState({ showAlertDialog: true });  
    console.log("in open dialog");
    this.govidLogin();
  };  
  
  // Handler to close the dialog  
  closeDialog = () => {  
    this.setState({ showAlertDialog: false });  
  };  
  

  ohidLogin(): void {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");
    if (
      (typeof sessionStorage.getItem("sessionId") == "undefined" ||
        sessionStorage.getItem("sessionId") == null) &&
      (code === null || code === "undefined")
    ) {
      sessionStorage.setItem("authType", "ohid");
      AuthProxy.loginRedirect();
      return;
    }
  }


  msidLogin() {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");
    if (
      (typeof sessionStorage.getItem("sessionId") == "undefined" ||
        sessionStorage.getItem("sessionId") == null) &&
      (code === null || code === "undefined")
    ) {
      sessionStorage.setItem("authType", "msid");
      AuthProxy.msidRedirect();
      return;
    }
  }

  
  govidLogin() {
    //const query = new URLSearchParams(window.location.search);
    //const code = query.get("code");
   // if (
    //  (typeof sessionStorage.getItem("sessionId") == "undefined" ||
    //    sessionStorage.getItem("sessionId") == null) &&
    //  (code === null || code === "undefined")
    //) {
    //  sessionStorage.setItem("authType", "govid");
      
    //  AlertDialog();
      //AuthProxy.govIdRedirect();
    //  return;
    //}
    //AlertDialog();
       // Handler to open the dialog  
       console.log("in govidLogin");
    return;

  }

  handleCloseAlertDialog = () => {  

    this.setState({ showAlertDialog: false });  
  };  


  render(): React.ReactElement {

    let env = "";

    //{this.state.showAlertDialog && (  
    //  <AlertDialog />  
    //)}  


    const DefaultLogo: FC<{theme: string}> =({theme}) =>{
      //apply environment button dynamically based on the environment

      if (window.location.href.includes("local")) { 

        env= "local";

        return <div className="bui-button-footer footer-btn-local" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }

      if (window.location.href.includes("dev")) { 

        env= "dev";

        return <div className="bui-button-footer footer-btn-dev" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }
      if (window.location.href.includes("qa") ) { 
        env = "qa";

        return <div className="bui-button-footer footer-btn-qa" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }
      if (window.location.href.includes("stg") ) { 
        env= "stg";

        return <div className="bui-button-footer footer-btn-stg" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }
      else  { 

        env= "prod";

        return <div className="bui-button-footer footer-btn-prod" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
    }
    
  }
    return (
      <React.Fragment>
        <div className="App">
          <div className="infoBar">
          </div>
          <div id="wrapper"> 
            <div className="content">
              <p id="pageHeader">Welcome to RxClaim Web</p>                         
              <Router>
                <div id="login-container">
                <div className="text">
                <p>
                  This site allows employers and plan sponsors to manage their
                  members' pharmacy benefits. 
                </p>
                <p>
                  If you are unable to login using your credentials, please contact
                  your client management/helpdesk representative, or use the
                  forgot password link on the next page.
                </p>
              </div>
                  <div id="login-content">
                   
                      <Button onClick={this.ohidLogin} className="bui-button is-primary">
                        One Healthcare ID Login
                      </Button>                    
                      <Button  onClick={this.msidLogin} className="bui-button is-primary">
                        Employee MSID Login
                      </Button>
                  </div>                 
                </div>
              </Router>
            </div>
          </div>
          <Footer className="footerContainer" /*logoContent={<DefaultLogo theme={"bui-uhc-theme"}/>}*/ /*linkConfig={{links: [{href:'#', label: <DefaultLogo theme={"bui-uhc-theme"} />, className: "linkStyle"}]}}*/>
          <DefaultLogo theme={"bui-uhc-theme"} ></DefaultLogo>
      </Footer>
        </div>
      </React.Fragment>
    );
  }
  //<Button  onClick={this.openDialog} className="bui-button is-primary">
  //OPTUM GovID Login
//</Button>
//<Dialog                                   
//  isOpen={this.state.showAlertDialog} // Pass visibility state  
//  onClose={this.closeDialog} // Pass close handler  
///> 
}
