//import React from 'react';  
import React, { useState } from 'react';
import "@better-ui/styles/optum/optum.css";
import { Button, Footer } from "@better-ui/react";
  

interface DialogProps {  
  isOpen: boolean;  
  onClose:any;  
}  


const Dialog: React.FC<DialogProps> = ({ isOpen, onClose }) => {  
const [openModal, setOpenModal] = React.useState(false);

  if (!isOpen) return null;  
  
  return (  
    <div  
      style={{  
        position: 'fixed',  
        top: 0,  
        left: 0,  
        width: '100%',  
        height: '100%',  
        backgroundColor: 'rgba(0, 0, 0, 0.5)',  
        display: 'flex',  
        justifyContent: 'center',  
        alignItems: 'center',  
        zIndex: 1000,  
      }}  
    >  
      <div  
        style={{  
          background: 'white',  
          padding: '20px',  
          borderRadius: '8px',  
          textAlign: 'center',  
        }}  
      >  
        <p>Warning!  This system contains U.S Government information.  By using this information 
            system, you are consenting to system monitoring for law enforcement and other purposes.
        </p>
        <p>Unauthorized or improper use of, or access to, this computer system may subject you to 
            state and federal criminal prosecution and penalties as well as civil penalties. 
        </p>
        <p> At any time, the government may intercept, search, and seize any communication or data transiting or 
            stored on this information system.  
        </p>  
            <Button onClick={onClose} className="bui-button is-primary">
                    Close
            </Button>
      </div>  
    </div>  
  );  
};  
  
export default Dialog;  


