import React from "react";
import * as AuthProxy from "./security/AuthProxy";
import './css/Form.css';
import Dialog from "./security/Dialog";

type LoginProps = {
  acc: Array<string>;
};

type InternalFormState = {
  openModal: boolean;
  selectedVal: string;
  };

export default class InternalForm extends React.Component<LoginProps, InternalFormState> {
  constructor(props: LoginProps){
    super(props);
    this.state = {
      openModal: false,  
      selectedVal: "",  
    };
  }
  //private openModal = false;
  //private selectedVal:string="";
  componentDidMount() { 
    window.process = { ...window.process }
}


handleClose = () => { 
  this.setState({openModal: false});
 // AuthProxy.formContinued(this.state.selectedVal);  
 this.executeAuthProxy();
 }

handleSubmit= (e: any) => {
  //console.log("form submit")
  e.preventDefault();
  //console.log(this.selectedVal);
  //redirecting to AuthProxy

  
  if (this.state.selectedVal.includes("rxfc6")) {  
    this.setState({ openModal: true });  
  } else {  
    this.setState({ openModal: false });  
    //AuthProxy.formContinued(this.state.selectedVal);  
    this.executeAuthProxy();
  }  

}

executeAuthProxy = () => {  
  console.log('Enter AuthProxy.');  
  AuthProxy.formContinued(this.state.selectedVal);   
}; 

handleChange= (e:any) => {
  const selectedVal = e.target.value;
  this.setState({ selectedVal });  
}


  
  render(): React.ReactElement {
    return (
      <React.Fragment>
        <div id="formWrapper">
        <div id="infoBar"></div>
        <div><h2 id="userWarning">You are internally provisioned for multiple environments.</h2>
        <h3 id="userWarning"> Please select which RxClaim environment you would like to access:</h3>
        </div>
        <div id="formBox">
        <form id="form" onSubmit={this.handleSubmit}>
        <select name="selectList" id="selectList" onChange={e => this.handleChange(e)}>
        <option value=""></option>
        {((this.props.acc)).map(a => {
            return (
            <option value={a}>{a}</option> 
          )
      })}
        </select>
        <input type="submit" id="submitBtn" />
        </form>
        </div></div>
        {this.state.openModal && <Dialog isOpen={this.state.openModal} onClose={this.handleClose}/>}
      </React.Fragment>
    );
  }
}