import pkceChallenge from "pkce-challenge";
import React from "react";
import ReactDOM from "react-dom";
import Form from "../Form";
import InternalForm from "../InternalForm";
import DOMPurify from 'dompurify';
import { ajax } from "jquery";
import Dialog from "./Dialog";

var assert = require("assert");
var token = require("../lib/token");
//const fs = require("fs");
//const path = require("path");
var OpenTokenAPI = require("../opentoken").OpenTokenAPI;
var SplunkLogger = require("splunk-logging").Logger;

 
// set up Splunk config 
var config = {
    token: "fc40e6c4-8396-11ec-b81b-005056947d26",
    url: "https://npe-dmz-hec-splunk.optum.com"
};
var Logger = new SplunkLogger(config);

// Enable SSL certificate validation
Logger.requestOptions.strictSSL = true;



export function returnForm() {
  return (
    <div><p>This is the form content</p></div> 
    )
}

export function send(payload:any) { 
  // TODO: ADD SERVER ORIGIN TO EACH SPLUNK LOG
  // HIGH PRIORITY: SPLUNK ERRORS, SERVER ORIGIN
  // TODO: LOG WHAT DATA CENTER, AND WHAT POD IN THAT DATA CENTER
  var data = JSON.stringify({
    // TODO: HOST TO SERVER POD NAME
    host: process.env.REACT_APP_API_ENV,
    sourcetype: "_json",
    source: "RxClaimWebSignOn",
    event: payload,
  });

  //console.log(process.env.REACT_APP_API_ENV)
  if(process.env.REACT_APP_API_ENV === "prod"){
    var splunkTokenProd=sessionStorage.getItem("splunkAuth");
    ajax({
      type: 'POST',
      url: "/services/collector/event",
      timeout: 2000,
      headers: {
        Authorization:
          `Splunk ${splunkTokenProd}`,
      },
      data: data,
      // making global false will result only the following ajax error being call 
      // and other error handler such as Presto error OpenErrorDialog not trigger
      global:false,
      dataType: "json",
      // function when ajax call return errors
      error: function(request, status, error) {
        // check if the request response returns an error message.
        if (request && request.responseJSON) {
          console.log(request.responseJSON.text + " code: " + request.responseJSON.code);
        } else {
          // log a general response if no response is return.
          console.log("The requested URL was not found on this server");
        }
      }
    });
  }

 else {
  var splunkTokenNP=sessionStorage.getItem("splunkAuthNP");
  ajax({
    type: 'POST',
    url: "/services/collector/eventstg",
    timeout: 2000,
    headers: {
      Authorization:
        `Splunk ${splunkTokenNP}`,
    },
    data: data,
    // making global false will result only the following ajax error being call 
    // and other error handler such as Presto error OpenErrorDialog not trigger
    global:false,
    dataType: "json",
    // function when ajax call return errors
    error: function(request, status, error) {
      // check if the request response returns an error message.
      if (request && request.responseJSON) {
        console.log(request.responseJSON.text + " code: " + request.responseJSON.code);
      } else {
        // log a general response if no response is return.
        console.log("The requested URL was not found on this server");
      }
    }
  });
 }
}

export function loginRedirect() {
  // redirect to OHID login after setting code verifier and challenger
  getChallenge();
  var pkceChallengeCodes = getChallenge();
  var verifier = pkceChallengeCodes["code_verifier"];
  sessionStorage.setItem("verifier", verifier);
  var challenger = pkceChallengeCodes["code_challenge"];
  sessionStorage.setItem("challenger", challenger);
  var origin = DOMPurify.sanitize(window.location.origin);
  var ohidEndPoint2;
  if(process.env.REACT_APP_API_ENV === "prod"){
    ohidEndPoint2 = `https://identity.onehealthcareid.com/oidc/authorize?client_id=rxcl028187N&response_type=code&scope=openid+profile+email+address+phone&redirect_uri=${origin}&code_challenge=${challenger}&code_challenge_method=S256`;
  } else{
    ohidEndPoint2 = `https://identity.nonprod.onehealthcareid.com/oidc/authorize?client_id=rxcl028187N&response_type=code&scope=openid+profile+email+address+phone&redirect_uri=${origin}&code_challenge=${challenger}&code_challenge_method=S256`;
  }
  window.location.href = ohidEndPoint2;
}

function getChallenge() {
  // receive PKCE challenge
  let pkceChallengeCodes: any = window.localStorage.getItem(
    "pkceChallengeCodes"
  );
  if ((!pkceChallengeCodes) || (pkceChallengeCodes === "{}") || (pkceChallengeCodes.length !== 211)) {
    pkceChallengeCodes = pkceChallenge(128);
    window.localStorage.setItem(
      "pkceChallengeCodes",
      JSON.stringify(pkceChallenge(128))
    );
  } else {
    pkceChallengeCodes = JSON.parse(pkceChallengeCodes);
  }
  return pkceChallengeCodes;
}

export function session(verifier: any, code: string, clientID: any, princeID: any) {
  // API call to OHID after successful OHID login
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append(
    "Cookie",
    "oidp=ATLVHSeOYHh0iijRFe0JrdAqT2qVGf973W-kjoIFIpnwwCvMyxA-y16-C1BLqA=="
  );
  var origin = window.location.origin;
  var urlencoded = new URLSearchParams();
  urlencoded.append("client_id", clientID);
  urlencoded.append("grant_type", "authorization_code");
  urlencoded.append("redirect_uri", origin);
  urlencoded.append("code", code as any);
  urlencoded.append("code_verifier", verifier);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow" as RequestRedirect,
  };
  var ohidEndPoint;
  if(process.env.REACT_APP_API_ENV === "prod"){
    ohidEndPoint = "https://identity.onehealthcareid.com/oidc/token" ;
  } else{
    ohidEndPoint = "https://identity.nonprod.onehealthcareid.com/oidc/token";
  }

  require('isomorphic-fetch');
  fetch(
    ohidEndPoint,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      // Splunk logging on the OHID token
      // parse through and recieve various tokens
      sessionStorage.setItem("token", result);
      var strID = sessionStorage.getItem("token");
      if (strID != null) {
        var objID = JSON.parse(strID);
        var authT = objID.access_token;
        //sessionStorage.setItem("authToken", authT);
        // decode token to retrieve uuid
        var decoded = decodeID(objID.id_token);
        sessionStorage.setItem("UUID", String(decoded.sub));
        var uuid = String(decoded.sub);
        // Optum Provisioning API call with uuid and auth token
        var splunkOhidToken = {
          message: "User has successful OHID login and token has been generated:",
          ohidToken: result,
          uuid: uuid
        }
        send(JSON.stringify(splunkOhidToken));
        opQuery(uuid, authT, princeID);
      }
    })
    .catch((error) => console.log("error", error));
}

export function opQuery(uuid: string, auth: string, princeID: string) {
  // build out API call to Optum Provsioning
  // after OHID login and OHID API call
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + auth);
  myHeaders.append("X-PrincipalID-Type", "uuid");
  myHeaders.append("X-PrincipalID", princeID);
  myHeaders.append("X-CorrelationID", "1234");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "BIGipServerapps.ocp-ctc-dmz-stg.optum.com_80=3058718218.20480.0000; TS01141950=013ee936b42d02b8238327c2be5d941322cb014f1cfd2926a68bcac6eadd4f98a3b3bdce1fae4f83a4d2fcbd16787f1b4f1bd03d23; TS01675af7=013ee936b49ae684aaf907f81de50d31c4c115d1876a25660fa97bdda210be4478af89477e0ec2206fe1b3ebf6b710ba578a8e93d2; c3a461391a176da6951f6e68039fa03a=bd4ade1279253d20f04e80e8f19c350d"
  );

  var raw = JSON.stringify({
    userID: {
      idType: "uuid",
      id: uuid,
    },
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow" as RequestRedirect,
  };
  var opEndPoint;
  if(process.env.REACT_APP_API_ENV === "prod"){
    opEndPoint = "https://api.onehealthcareid.com/api/v1.1/authorization/user?op=get";
  } else{
    opEndPoint = "https://api.nonprod.onehealthcareid.com/api/v1.1/authorization/user?op=get";
  }
  require('isomorphic-fetch');
  fetch(
    opEndPoint,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      // Parse Optum Provisioning results
      var opObjID = JSON.parse(result);
      // Splunk logging on the OP information returned
      var splunkUserOPData = {
        message: "User's Optum Provisioning data:",
        value: result
      }
      send(JSON.stringify(splunkUserOPData));

      if (opObjID.status === "SUCCESS") {
        // API call successful
        //console.log("Redirect to link");
        // Set email
        var email = opObjID.user.contact.registeredEmail;
        sessionStorage.setItem("email", email);
        var upn, url, ohid, urlName, upnName;
        var acts = opObjID.user.accounts;
        var allUrl:any = [];
        var urlNameVal:any = [];
        var upnNameVal:any = [];
        for (var i = 0; i < acts.length; i++) {
          for (
            var j = 0;
            j <
            acts.at(i).organizations.at(0).services.at(0).userDetails
              .userIdentifiers.length;
            j++
          ) {
            if (
              acts
                .at(i)
                .organizations.at(0)
                .services.at(0)
                .userDetails.userIdentifiers.at(j)
                .name.includes("_url")
            ) {
              url = acts.at(i).organizations.at(0).services.at(0).userDetails.userIdentifiers.at(j).value;
              urlName = acts.at(i).organizations.at(0).services.at(0).userDetails.userIdentifiers.at(j).name;
              allUrl.push(url);
              urlNameVal.push([urlName,url]);
            }
            if (
              acts
                .at(i)
                .organizations.at(0)
                .services.at(0)
                .userDetails.userIdentifiers.at(j)
                .name.includes("_upn")
            ) {
              upn = acts.at(i).organizations.at(0).services.at(0).userDetails.userIdentifiers.at(j).value;
              upnName = acts.at(i).organizations.at(0).services.at(0).userDetails.userIdentifiers.at(j).name;
              upnNameVal.push([upnName,upn]);
            }
            if (
              acts
                .at(i)
                .organizations.at(0)
                .services.at(0)
                .userDetails.userIdentifiers.at(j)
                .name.includes("Optum ID")
            ) {
              ohid = acts
                .at(i)
                .organizations.at(0)
                .services.at(0)
                .userDetails.userIdentifiers.at(j).value;
              sessionStorage.setItem('ohid', ohid);
            }
          }
        }
        sessionStorage.setItem("urlNameVal", urlNameVal);
        sessionStorage.setItem("upnNameVal",upnNameVal);
        if(allUrl.length > 1){
          // user chooses
          // set var urlValChosen based of what url user chooses
          //Render form component
          sessionStorage.setItem('allUrls', allUrl);
          //console.log("form rendered");
          return ReactDOM.render(
            <React.StrictMode>
              <Form urls={allUrl} />
            </React.StrictMode>,
            document.getElementById("root")
          );
        }else{
          if(upn !== undefined && url !== undefined){
            sessionStorage.setItem("upn", upn);
            sessionStorage.setItem("url", url);
            sessionStorage.setItem('ohid', ohid);
            sessionStorage.setItem('email', email);
            //redirect
            window.location.replace("//" + url);
        }else {
            // user has accounts in Optum Provisioning but there are no url, upn values assosciated with those accounts
            alert(
              "Sorry, you do not have access. Please contact your account manager to provision access. Redirecting to original sign on page now"
            );
            var origin = sessionStorage.getItem("origin");
            // Splunk logging if the user does not have access
            var splunkOpDataNoUpnUrl = {
              // Message can be anything; doesn't have to be an object
              message: "User has accounts in OP but there are no url, upn values associated with those accounts",
              email: email,
              ohid: ohid,
              uuid: uuid,
              opdata: result
            };
        
            send(JSON.stringify(splunkOpDataNoUpnUrl));

            sessionStorage.clear();
            // redirect to origin page
            window.location.href = `${origin}`;
          }
        }
      } else {
        // acccess is no good so alert user
        alert(
          "Sorry, you do not have access. Please contact your account manager to provision access. Redirecting to original sign on page now"
        );
        // Splunk logging if the user does not have access
        var splunkOpNoAccess = {
          // Message can be anything; doesn't have to be an object
          message: "User has no access, not set up in OP",
          value: uuid
        };
    
      send(JSON.stringify(splunkOpNoAccess));

        origin = sessionStorage.getItem("origin");
        sessionStorage.clear();
        // redirect to origin page
        window.location.href = `${origin}`;
      }
    })
    .catch((error) => {
      console.log("error", error);
      // Splunk logging if the user does not have access
      var splunkOpFail = {
        // Message can be anything; doesn't have to be an object
        message: "OP call has failed.",
        uuid: uuid,
        errorMsg: error
      };
      send(JSON.stringify(splunkOpFail));
      // TODO: POPUP THAT TELLS USER THAT OP HAS FAILED AND TO REACH OUT TO ADMIN> BRING THEM BACK TO 
    }
  );
}

export function opCont(url:any) {
  var urlValChosen = url;
  var urlNameVal:any = sessionStorage.getItem("urlNameVal");
  var upnNameVal:any = sessionStorage.getItem("upnNameVal");
  urlNameVal = urlNameVal.split(",");
  upnNameVal = upnNameVal.split(",");
  var urlNameValReal:any = [];
  var upnNameValReal:any = [];
  for(var i=0;i<urlNameVal.length;i=i+2){
    if(urlNameVal[i] === undefined){
      break;
    }
    urlNameValReal.push([urlNameVal[i],urlNameVal[i+1]]);
    upnNameValReal.push([upnNameVal[i],upnNameVal[i+1]]);
  }
  var urlNameChosen;
  for(i=0;i<urlNameValReal.length;i++){
    if(urlNameVal[i] === undefined){
      break;
    }
    if(urlNameValReal[i][1] === urlValChosen){
      urlNameChosen = urlNameValReal[i][0];
    }
  }
  var splitNameVals = urlNameChosen.split("_");
  var connector = splitNameVals[1];
  var upnEquivalent;
  for(i=0; i<upnNameValReal.length;i++){
    if(urlNameVal[i] === undefined){
      break;
    }
    if(upnNameValReal[i][0].includes(connector)){
      upnEquivalent = upnNameValReal[i][1];
    }
  }
  // Splunk logging on what the user chooses
  var uuid = sessionStorage.getItem("UUID");
  var ohid = sessionStorage.getItem("ohid");
  var email = sessionStorage.getItem("email");
  var splunkRedirect1 = {
    message: "User has been directed to the RxClaim Web instance for the first time (pre Ping Fed check)",
    url: urlValChosen,
    subject: upnEquivalent,
    uuid: uuid,
    ohid: ohid,
    email: email
  };
  send(JSON.stringify(splunkRedirect1));
  
  document.cookie = "kcdtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  sessionStorage.setItem("upn", upnEquivalent);
  sessionStorage.setItem("url", urlValChosen);
  window.location.replace("//" + encodeURI(urlValChosen));
}

export function decodeID(token: any) {
  // decode auth token to get uuid
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  } else {
    return null;
  }
}

export function getCookie(name: any) {
  function escape(s: any) {
    return s.replace(/([.*+?$(){}|\]\\])/g, "\\$1");
  }
  var match = document.cookie.match(
    RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
  );
  return match ? match[1] : null;
}

export function decodeOpenToken(opentoken: any) {
  token.decode(opentoken);
}

export function postPingFed(sessionId: any, pass: any) {
  // sessionID from decoded resume variable
  var sessionID = sessionId;
  if (document.cookie.match(/kcdtoken/) !== null) {
    // if cookie is already there, then clear
    console.log("cookie is already there")
    document.cookie = "kcdtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  }

  // create opentokenapi instance
  var cipherId = 1;
  //obfuscated password
  var password = pass;
  var otkOptions = {};
  var otkapi = new OpenTokenAPI(cipherId, password, otkOptions);
  var user_uuid: any = sessionStorage.getItem("UUID");
  var user_email = sessionStorage.getItem("email");
  var user_ohid = sessionStorage.getItem("ohid");
  var user_upn = sessionStorage.getItem("upn");
  var user_url = sessionStorage.getItem("url");
  var userData = {
    subject: user_upn,
    email: user_email,
    ohid: user_ohid,
    uuid: user_uuid,
    url: user_url,
  };

  if (userData.subject == null) {
    console.log("Open token is null, redirecting");
    window.location.replace(origin);
    return null;
  }

  otkapi.createToken(userData, function (err: any, result: any) {
    console.log("creating open token....");
    console.log("Test OpenTokenAPI::createToken... ");
    assert.ifError(err);
    console.log("OK\n");

    var opentoken = sessionStorage.getItem("opentoken");

    // initiate post request to pingfederate
    if (opentoken !== null) {
      // Splunk logging when the open token is created
      var uuid = sessionStorage.getItem("UUID");
      var ohid = sessionStorage.getItem("ohid");
      var email = sessionStorage.getItem("email");
      var upn = sessionStorage.getItem("upn");
      var url = sessionStorage.getItem("url");
      var splunkOpenToken = {
        message: "Open Token has been created for the user, sending to Ping Fed now.",
        otk: opentoken,
        subject: upn,
        url: url,
        uuid: uuid,
        ohid: ohid,
        email: email
      }
      send(JSON.stringify(splunkOpenToken));
      
      otkapi.parseToken(opentoken, function (err: any, result: any) {
        console.log("PARSING");
      });
      postOpenToken(opentoken, sessionID, user_url);
    }
  });
}

export function postOpenToken(opentoken: any, sessionId: any, url: any) {
  //check to make sure kcdtoken not already in cookies 
  
  if (document.cookie.match(/kcdtoken/) !== null) {
    // if cookie is already there, then clear
    console.log("cookie is already there")
    document.cookie = "kcdtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  }
  document.cookie = `kcdtoken=${opentoken};domain=.optum.com;SameSite=Strict`;

  var myHeaders = new Headers();
  myHeaders.append("Cookie", `kcdtoken=${opentoken};domain=.optum.com;SameSite=Strict`);
  myHeaders.append("Access-Control-Allow-Origin", "*");

  var requestOptions = {
    method: "POST",
    credentials: "include" as RequestCredentials,
    mode: "no-cors" as RequestMode,
    headers: myHeaders,
    redirect: "follow" as RequestRedirect,
  };
  var pingEndPoint;
  if(process.env.REACT_APP_API_ENV === "prod"){
    pingEndPoint = `https://kcdauthgateway-entiam.optum.com/as/${sessionId}/resume/as/authorization.ping`;
  } else{
    pingEndPoint = `https://kcdauthgateway-stg-entiam.optum.com/as/${sessionId}/resume/as/authorization.ping`;
  }
  require('isomorphic-fetch');
  fetch(
    pingEndPoint,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      window.location.replace("//" + encodeURI(url));
    })
    .catch((error) => console.log("error", error));
}

//
//
//START OF THE MSID PATH - INTERNAL USERS
//
//

export function msidRedirect(){
  getChallenge();
  var pkceChallengeCodes = getChallenge();
  var verifier = pkceChallengeCodes["code_verifier"];
  sessionStorage.setItem("msidVerifier", verifier);
  var challenger = pkceChallengeCodes["code_challenge"];
  sessionStorage.setItem("msidChallenger", challenger);
  var origin = DOMPurify.sanitize(window.location.origin);
  var msidEndPoint2;
  var clientIdProd = "9843ed4b-0dac-45db-9e2d-ceb8c8c492dc";
  var clientId = "c0cecd33-54bf-4ee3-8373-ae694375b631";
  if(process.env.REACT_APP_API_ENV === "prod"){
    msidEndPoint2 = `https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421/oauth2/v2.0/authorize?client_id=${clientIdProd}&response_type=code&redirect_uri=${origin}&scope=openid&code_challenge=${challenger}&code_challenge_method=S256&x-idp=azuread`
  } else{
    msidEndPoint2 = `https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${origin}&scope=openid&code_challenge=${challenger}&code_challenge_method=S256&x-idp=azuread`
  }
  window.location.href = msidEndPoint2;
}

export function msidToken(verifier: any, code: string, clientID: any) {
  // API call to azure after successful MSID login
  var origin = window.location.origin;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("origin", origin);
  var urlencoded = new URLSearchParams();
  urlencoded.append("client_id", clientID);
  urlencoded.append("grant_type", "authorization_code");
  urlencoded.append("redirect_uri", origin);
  urlencoded.append("code", code as any);
  urlencoded.append("code_verifier", verifier);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow" as RequestRedirect,
  };
  var msidEndPoint;
  if(process.env.REACT_APP_API_ENV === "prod"){
    msidEndPoint = "https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421/oauth2/v2.0/token";
  } else{
    msidEndPoint = "https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421/oauth2/v2.0/token";
  }

  require('isomorphic-fetch');
  fetch(
    msidEndPoint,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      // parse through and recieve various tokens
      sessionStorage.setItem("msidToken", result);
      var msidResponse = sessionStorage.getItem("msidToken");
      if (msidResponse != null) {
        // change MSID response to readable format
        var msidID = JSON.parse(msidResponse);
        var msidAuthT = msidID.access_token;

        //decode access_token to get display name 
        var accessDecoded=decodeID(msidAuthT);
        var displayName=accessDecoded.given_name;
        //console.log(displayName);
        sessionStorage.setItem("firstName", displayName);

        // decode ID token to retrieve uuid
        var msidDecoded = decodeID(msidID.id_token);
        var msidEmployeeId = String(msidDecoded.employeeID);
        var msidSAMA= String(msidDecoded.sAMAccountName);
        
        //set to session storage
        //sessionStorage.setItem("msidEmployee", msidEmployeeId);
        sessionStorage.setItem("sAMAccountName", msidSAMA);

        var msidAuthDecoded = decodeID(msidAuthT);
        sessionStorage.setItem("msidAuthT", String(msidAuthDecoded));
        //var msidAuthTDName = String(msidAuthDecoded.unique_name);
        var msidAuthTDUpn = String(msidAuthDecoded.upn);

        sessionStorage.setItem("msid", msidSAMA);
        //sessionStorage.setItem("msidUuid", msidEmployeeId);
        sessionStorage.setItem("msidEmail", String(msidAuthTDUpn));

        var splunkMsidToken = {
          message: "User has successful MSID login and token has been generated:",
          msidToken: msidID.access_token,
          msid: msidSAMA,
          //employeeId: msidEmployeeId,
          email: String(msidAuthTDUpn)
        }
        send(JSON.stringify(splunkMsidToken));

        retrieveAdLookup(msidEmployeeId, msidID.access_token);
      }
    })
    .catch((error) => console.log("error", error));
}

export function retrieveAdLookup(empId : any, access_token : any){


   // @ts-ignore
   //const env = window['environments'];
   //console.log(env);
   let url = `https://gateway-core.optum.com/app/rxclaim-web-backend-service/v1/ad-lookup-service/getAdLookup?employeeId=${empId}`;
   

   if(process.env.REACT_APP_API_ENV === 'stg') {
       url = `https://gateway-stage-core.optum.com/app/stg/rxclaim-web-backend-service/v1/ad-lookup-service/getAdLookup?employeeId=${empId}`;
   }
  
  return fetch(url, {
    method: "GET",
    headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
    })
  })
  .then((res:any) => res.json())
  .then((data) => { 
    //console.log("GOT TO BACKEND");
    //console.log(data);

    if (data.errorMsg !== null || data.samAccount == null) {
      alert("Your Employee ID has not been provisioned for access. Please contact your account manager.")
      window.location.replace(window.location.origin);
    }

    // split account values and sort/reverse to put the empty value at the end
    var samAccounts:any = data.samAccount.split(',');
    samAccounts.sort();
    samAccounts.reverse();
    //console.log(samAccounts);
  
    var envAccMap = new Map();
    var i=0;
    var curEnv = "";
    var curAcc = "";
    var allEnvs = [];
    //map environments to access
    // while(i<samAccounts.length-1){
    //   // check if its a new environment
    //   curEnv = samAccounts[i].split('-')[0];
    //   curAcc = samAccounts[i].split('-')[1];
    //   if(envAccMap.has(curEnv)){
    //     // same environment
    //     envAccMap.set(curEnv, envAccMap.get(curEnv).push(curAcc));
    //   }else{
    //     // new environment
    //     allEnvs.push(curEnv);
    //     var accounts:any = [curAcc];
    //     envAccMap.set(curEnv, accounts);
    //   }
    //   i++;
    // }

    //console.log(envAccMap);
    // var mapStr = JSON.stringify(Array.from(envAccMap.entries()));
    // //console.log(mapStr);
    // sessionStorage.setItem("mappedValsStr", mapStr);

    var splunkMsidVals = {
      message: "User's RXCLAD Provisioning data:",
      msid: sessionStorage.getItem("msid"),
      //employeeId: sessionStorage.getItem("msidUuid"),
      email: sessionStorage.getItem("msidEmail"),
      value: samAccounts
    }
    send(JSON.stringify(splunkMsidVals));

    //check all access to only bring up form if user profiles are greater than one
   
      // have users choose from dropdown, looking at env name. Then route to linked url

    samAccounts.pop();
      console.log("number of accounts" + samAccounts.length);
    if (samAccounts.length > 1)
    {
      return ReactDOM.render(
        <React.StrictMode>
          <InternalForm acc={samAccounts} />
        </React.StrictMode>,
        document.getElementById("root")
      );
    }
  // user only has 1 profile - select first entry and proceed

  else if(samAccounts.length == 1){
    formContinued(samAccounts[0]);
  }else{
    // user is set up in splunk but has no environments set up
    var splunkLdapAccountError = {
      message: "User is set up in LDAP but has no accounts set:",
      employeeId: empId,
    }
    send(JSON.stringify(splunkLdapAccountError));
  }
   
  })
  .catch(error => {
    console.log('Backend call error', error);
    var splunkLdapError = {
      message: "User has had a backend call erre during LDAP connection:",
      employeeId: empId,
      error: error
    }
    send(JSON.stringify(splunkLdapError));
  });
}


export function formContinued(selectedVal:any){

  console.log("Selected Value:" + selectedVal);
  sessionStorage.setItem("upn_msid", selectedVal);
  var curEnv = selectedVal.split('-')[0];
  var curAcc = selectedVal.split('-')[1];
  

  //const [openModal, setOpenModal] = React.useState(false);
  //const handleClose = () => {
  //  console.log("handleClose");
  //  setOpenModal(false);
//}
 
  
  console.log(curEnv);
  console.log(curAcc);

  
  sessionStorage.setItem("envSelected", curEnv);
  sessionStorage.setItem("accSelected", curAcc);


  var first:any=sessionStorage.getItem('firstName');


  // create environment to url map and grab url when user hits submit
  var urlLinks = new Map();
  var url="";
  if(process.env.REACT_APP_API_ENV === 'stg') {
    // stage link setup
    urlLinks.set("rxcl1tst", "rxclaim-uat.optum.com");
    urlLinks.set("rxcl3tst", "rxclaim-uat3.optum.com");
    urlLinks.set("rxbk1uat", "rxclaim-uat1.optum.com");
    urlLinks.set("rxbk2uat", "rxclaim-uat2.optum.com");
    urlLinks.set("rxt1tst", "rxclaim-tst.optum.com");
    urlLinks.set("rxbk1pt", "rxclaim-pt.optum.com");
	  urlLinks.set("rxdv1", "rxclaim-qa.optum.com");
	  urlLinks.set("rxbk1qa", "rxclaim-qa1.optum.com");
	  urlLinks.set("rxbk2qa", "rxclaim-qa2.optum.com");
	  urlLinks.set("rxmcmqa", "rxclaim-mcmqa.optum.com");
	  urlLinks.set("rxmcmuat", "rxclaim-mcmuat.optum.com");
	  urlLinks.set("rxbchuat", "rxclaim-bchuat.optum.com");
    urlLinks.set("rxfc6tst", "rxclaim-ofe-stg.optum.com");
  }else{
    // prod link
    urlLinks.set("rxbk1", "rxclaim-bk1.optum.com");
    urlLinks.set("rxbk2", "rxclaim-bk2.optum.com");
    urlLinks.set("rxcl1", "rxclaim-cl1.optum.com");
    urlLinks.set("rxcl2", "rxclaim-cl2.optum.com");
    urlLinks.set("rxcl3", "rxclaim-cl3.optum.com");
    urlLinks.set("rxcl4", "rxclaim-cl4.optum.com");
    urlLinks.set("rxcl5", "rxclaim-cl5.optum.com");
    urlLinks.set("rxcnp", "rxclaim-cnp.optum.com");
    urlLinks.set("rxmcm", "rxclaim-mcm.optum.com");
    urlLinks.set("rxbch", "rxclaim-bch.optum.com");
    urlLinks.set("rxfc6", "rxclaim-ofe-fc6.optum.com");
  }
  // redirect to selected link
  var selectedEnv = sessionStorage.getItem("envSelected");
  url = urlLinks.get(selectedEnv);

  console.log(url);

  var splunkMsidAcc = {
    message: "User has been directed to the RxClaim Web instance for the first time (pre Ping Fed check)",
    msid: sessionStorage.getItem("msid"),
    //employeeId: sessionStorage.getItem("msidUuid"),
    email: sessionStorage.getItem("msidEmail"),
    url: url,
    subject: sessionStorage.getItem("upn_msid")
  }
  send(JSON.stringify(splunkMsidAcc));
  

  sessionStorage.setItem("selectedUrl", url);
  window.location.replace("//" + url);
}

export function msidPostPingFed(sessionId: any, pass: any) {
  // sessionID from decoded resume variable
  var sessionID = sessionId;
  if (document.cookie.match(/kcdtoken/) !== null) {
    // if cookie is already there, then clear
    console.log("cookie is already there")
    document.cookie = "kcdtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
    //add splunk
  }
  // create opentokenapi instance
  var cipherId = 1;
  //obfuscated password
  var password = pass;
  var otkOptions = {};
  var otkapi = new OpenTokenAPI(cipherId, password, otkOptions);
  var msid_uuid: any = sessionStorage.getItem("msidUuid");
  var msid_email = sessionStorage.getItem("msidEmail");
  var msid = sessionStorage.getItem("msid");
  var msid_upn = sessionStorage.getItem("upn_msid");
  var msid_url = sessionStorage.getItem("selectedUrl");

  var userData = {
    subject: msid_upn,
    email: msid_email,
    ohid: msid,
    uuid: msid_uuid,
    url: msid_url,
  };
  if (userData.subject == null) {
    console.log("Open token is null, redirecting");
    window.location.replace(origin);
    return null;
  }

  otkapi.createToken(userData, function (err: any, result: any) {
    console.log("creating open token....");
    console.log("Test OpenTokenAPI::createToken... ");
    assert.ifError(err);
    console.log("OK\n");
    var opentoken = sessionStorage.getItem("opentoken");

    // initiate post request to pingfederate
    if (opentoken !== null) {
      // Splunk logging when the open token is created
      var uuid = sessionStorage.getItem("msidUuid");
      var msid = sessionStorage.getItem("msid");
      var email = sessionStorage.getItem("msidEmail");
      var upn = sessionStorage.getItem("envSelected")+"-"+sessionStorage.getItem("accSelected");
      var url = sessionStorage.getItem("selectedUrl");

      var splunkOpenToken = {
        message: "Open Token has been created for the user with MSID, sending to Ping Fed now.",
        otk: opentoken,
        subject: upn,
        url: url,
        //employeeId: uuid,
        msid: msid,
        email: email
      }
      send(JSON.stringify(splunkOpenToken));
      
      otkapi.parseToken(opentoken, function (err: any, result: any) {
        console.log("PARSING");
      });
      msidPostOpenToken(opentoken, sessionID, msid_url);
    }
  });
}

export function msidPostOpenToken(opentoken: any, sessionId: any, url: any) {
  //check to make sure kcdtoken not already in cookies 
  if (document.cookie.match(/kcdtoken/) !== null) {
    // if cookie is already there, then clear
    console.log("cookie is already there")
    document.cookie = "kcdtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  }
  document.cookie = `kcdtoken=${opentoken};domain=.optum.com;SameSite=Strict`;

  var myHeaders = new Headers();
  myHeaders.append("Cookie", `kcdtoken=${opentoken};domain=.optum.com;SameSite=Strict`);
  myHeaders.append("Access-Control-Allow-Origin", "*");

  var requestOptions = {
    method: "POST",
    credentials: "include" as RequestCredentials,
    mode: "no-cors" as RequestMode,
    headers: myHeaders,
    redirect: "follow" as RequestRedirect,
  };
  var pingEndPoint;
  if(process.env.REACT_APP_API_ENV === "prod"){
    pingEndPoint = `https://kcdauthgateway-entiam.optum.com/as/${sessionId}/resume/as/authorization.ping`;
  } else{
    pingEndPoint = `https://kcdauthgateway-stg-entiam.optum.com/as/${sessionId}/resume/as/authorization.ping`;
  }
  require('isomorphic-fetch');
  fetch(
    pingEndPoint,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      window.location.replace("//" + url);
    })
    .catch((error) => {
      var uuid = sessionStorage.getItem("msidUuid");
      var msid = sessionStorage.getItem("msid");
      var email = sessionStorage.getItem("msidEmail");
      var upn = sessionStorage.getItem("envSelected")+"-"+sessionStorage.getItem("accSelected");
      var url = sessionStorage.getItem("selectedUrl");
      console.log("error", error)
      var splunkPingError = {
        message: "User has had an unsuccessful Ping authentication:",
        otk: opentoken,
        subject: upn,
        url: url,
        uuid: uuid,
        ohid: msid,
        email: email
      }
      send(JSON.stringify(splunkPingError));
    });
}